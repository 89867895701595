import React, { FC } from "react";
import { IEnrolledCourse } from "../../../../utils/services/courseService";

type Props = {
  data: IEnrolledCourse[];
};
const EnrolledCourseRow: FC<Props> = ({ data }) => {
  return (
    <>
      {data.map((row) => (
        <tr key={row.id}>
          <td>{row.Courses.name}</td>
          <td>{new Date(row.enrollment_date).toDateString()}</td>
          <td>{row.percentage}%</td>
        </tr>
      ))}
    </>
  );
};

export default EnrolledCourseRow;
