import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { useEnrolledCourses } from "../../../../utils/services/courseService";
import EnrolledCourseRow from "./EnrolledCourseRow";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
}

const Th = ({ children }: ThProps) => {
  const { classes } = useStyles();

  return (
    <th className={classes.th}>
      <UnstyledButton className={classes.control}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
        </Group>
      </UnstyledButton>
    </th>
  );
};

const EnrolledCourse = () => {
  const { id } = useParams();
  const { data, isSuccess, error } = useEnrolledCourses(id as string);
  return (
    <ScrollArea>
      {isSuccess && (
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{ tableLayout: "fixed", minWidth: 700 }}
        >
          <thead>
            <tr>
              <Th>Name</Th>
              <Th>Enrolled Date</Th>
              <Th>Progress</Th>
              <Th>Action</Th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <EnrolledCourseRow data={data} />
            ) : (
              <tr>
                <td>
                  <Text weight={500} align="center">
                    Nothing found
                  </Text>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </ScrollArea>
  );
};

export default EnrolledCourse;
