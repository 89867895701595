import { Chip, Loader, ScrollArea, Switch, Table, Text } from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { TeamScope } from "../../../../utils/constants";
import { useCreatedExam } from "../../../../utils/services/examService";

const CreatedExam = () => {
  const { id } = useParams();
  const { data, isSuccess, isLoading } = useCreatedExam(id as string);

  return (
    <ScrollArea>
      {isLoading && <Loader />}

      {isSuccess && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Duration</th>
              <th>Allow Retake</th>
              <th>Show Result</th>
              <th>Price</th>
              <th>Scope</th>
              <th>Status</th>
              <th>No of Questions</th>
              <th>No of Teacher</th>
              <th>Enrolled Student</th>
              <th>Team</th>
              <th>No of results</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((x) => {
                return (
                  <tr>
                    <td
                      style={{
                        maxLines: 1,
                        maxWidth: "300px",
                        overflowX: "hidden",
                      }}
                    >
                      {x?.name}
                    </td>
                    <td>{x?.start_time}</td>
                    <td>{x?.end_time}</td>
                    <td>{x?.duration}</td>
                    <td>{x?.allowed_retake}</td>
                    <td>
                      <Text color={x?.show_result_details ? "green" : "red"}>
                        {x?.show_result_details ? "yes" : "No"}
                      </Text>
                    </td>
                    <td>{x?.price}</td>
                    <td>
                      <Text color={x?.scope === 1 ? "green" : "blue"}>
                        {TeamScope[x?.scope]}
                      </Text>
                    </td>
                    <td>{x?.status}</td>
                    <td>{x?._count?.QuestionSetQuestions}</td>
                    <td>{x?._count?.QuestionSetTeachers}</td>
                    <td>{x?._count?.QuestionSetEnrollments}</td>
                    <td>{x?._count?.TeamQuestionSets}</td>
                    <td>{x?._count?.QuestionSetResults}</td>
                  </tr>
                );
              })
            ) : (
              <tr>User haven't created any live session </tr>
            )}
          </tbody>
        </Table>
      )}
    </ScrollArea>
  );
};

export default CreatedExam;
