import { ScrollArea, Table, Text } from "@mantine/core";
import { useParams } from "react-router-dom";
import CourseList from "../../../../components/Course/CourseList";
import { useCreatedCourses } from "../../../../utils/services/courseService";

const CreatedCourse = () => {
  const { id } = useParams();

  const { data, isSuccess, error } = useCreatedCourses(id as string);

  return <ScrollArea>{isSuccess && <CourseList data={data} />}</ScrollArea>;
};

export default CreatedCourse;
