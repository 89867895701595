import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface ILogin {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  token: {
    expiresIn: number;
    token: string;
  };
}

export const useLogin = () => {
  return useMutation(
    [api.auth.login],
    ({ email, password }: { email: string; password: string }) => {
      return httpClient.post<ILogin>(api.auth.login, { email, password });
    },

    {
      onError: (data, {}, context) => {
        localStorage.removeItem("token");
      },
      onSuccess: (data) =>
        localStorage.setItem("token", data?.data?.token?.token),
    }
  );
};

export const useReAuth = () => {
  return useQuery(
    [api.auth.reAuth],
    () => {
      return httpClient.get<ILogin>(api.auth.reAuth);
    },

    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,

      onError: () => {
        localStorage.removeItem("token");
      },
      select: (data) => data.data,
      enabled: !!localStorage.getItem("token"),
      onSuccess: (data) => {
        localStorage.setItem("token", data?.token?.token);
      },
    }
  );
};
