import {
  Avatar,
  createStyles,
  Group,
  HoverCard,
  Loader,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { IconAt, IconCalendar, IconPhoneCall } from "@tabler/icons";
import React from "react";
import { Link, useParams } from "react-router-dom";
import UserProfile from "../../components/UserProfile";
import { useGetAssignment } from "../../utils/services/assignmentService";

const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

const Assignment = () => {
  const { classes } = useStyles();
  const { id } = useParams();
  const { isLoading, data, isSuccess } = useGetAssignment(id as string);
  if (isLoading) {
    return <Loader />;
  }
  if (isSuccess && data.length === 0) {
    return <Paper>There is no assignment for this course</Paper>;
  }

  return (
    <>
      {isSuccess ? (
        data.map((d) => (
          <HoverCard>
            <HoverCard.Target>
              <Paper m={4} shadow="xl" radius={20} p={10}>
                <Text size="lg" weight={500} className={classes.name}>
                  {d.name}
                </Text>

                <Group noWrap spacing={10} mt={3}>
                  <IconCalendar
                    stroke={1.5}
                    size={16}
                    className={classes.icon}
                  />
                  <Text size="xs" color="dimmed">
                    {new Date(d.due_date).toDateString()}
                  </Text>
                </Group>

                <Group noWrap spacing={10} mt={5}>
                  <IconPhoneCall
                    stroke={1.5}
                    size={16}
                    className={classes.icon}
                  />
                  <Text size="xs" color="dimmed">
                    {"phone"}
                  </Text>
                </Group>
              </Paper>
            </HoverCard.Target>
            <HoverCard.Dropdown sx={{ borderRadius: "20px" }}>
              <Title size={"md"}>Created By</Title>

              <UserProfile user={d.Users} />
            </HoverCard.Dropdown>
          </HoverCard>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default Assignment;
