import { useQuery } from "@tanstack/react-query";
import { IPaginatedResult } from "../Types";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface IUser {
  user_id: string;
  first_name: string;
  last_name: string;
  email?: string;
  profession?: string;
  mobile_number?: string;
  bio?: string;
  website_url?: string;
  facebook_url?: string;
  twitter_url?: string;
  youtube_url?: string;
  github_url?: string;
  instagram_url?: string;
  image_url?: string;
  linkedin_url?: string;
  created_by?: string;
  created_on?: string;
  updated_by?: string;
  updated_on?: string;
}

export interface IUserProvider {
  uid: string;
  displayName: string;
  email?: string;
  photoURL: string;
  providerId: string;
  phoneNumber?: string;
}
export interface IUserCount {
  Students: number;
  Courses: number;
  LiveSessions: number;
  QuestionPoolTeachers: number;
  QuestionPools: number;
  TeamUsers: number;
  Teams: number;
  live_session_moderators: number;
  Teachers: number;
}
export interface IUserDetails extends IUser {
  uid: string;
  emailVerified: boolean;
  displayName: string;
  photoURL: string;
  disabled: boolean;
  providerData: IUserProvider[];
}

const listUser = async (page: number, search: string | null) =>
  await httpClient.get<IPaginatedResult<IUser>>(api.user.get(page, search));

export const useListUsers = (page: number, search: string | null) =>
  useQuery([api.user.get, page, search], () => listUser(page, search), {
    select: ({ data }) => data || [],
    keepPreviousData: true,
  });

const userDetail = async (userId: string) =>
  await httpClient.get<IUserDetails>(api.user.one(userId));
export const useUserDetail = (userId: string) =>
  useQuery([api.user.one(userId)], () => userDetail(userId), {
    select: ({ data }) => data,
  });
