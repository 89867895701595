import { Loader, ScrollArea, Table } from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import { useEnrolledSession } from "../../../../utils/services/sessionService";

const EnrolledSession = () => {
  const { id } = useParams();
  const { data, isSuccess, error, isLoading } = useEnrolledSession(
    id as string
  );
  return (
    <ScrollArea>
      {isLoading && <Loader />}
      {isSuccess && (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Enrollment Date</th>
              <th>Enrollment Status</th>
            </tr>
          </thead>

          {data &&
            data.length > 0 &&
            data.map((x) => {
              return (
                <tr>
                  <td>{x.LiveSessions.name}</td>
                  <td>{new Date(x.enrollment_date).toDateString()}</td>
                  <td>{x.EnrollmentMemberStatus}</td>
                </tr>
              );
            })}
        </Table>
      )}
    </ScrollArea>
  );
};

export default EnrolledSession;
