import { useQueries, useQuery } from "@tanstack/react-query";
import { IPaginatedResult, ITeacherProfile } from "../Types";
import { api } from "./service-api";
import { httpClient } from "./service-axios";
import { IUser } from "./userServices";

export interface IEnrolledCourse {
  id: string;
  current_lesson_id: string;
  current_lesson_watched: number;
  percentage: number;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  status: number;
  course_id: string;
  user_id: string;
  enrollment_date: string;
  price: "0";
  Courses: {
    id: string;
    name: string;
    slug: string;
    thumbnail_url: string;
  };
}

const enrolledCourses = async (userId: string) =>
  await httpClient.get<IEnrolledCourse[]>(api.course.getUserCourse(userId));

export const useEnrolledCourses = (userId: string) =>
  useQuery([api.course.getUserCourse(userId)], () => enrolledCourses(userId), {
    select: ({ data }) => data || [],
  });

export interface ICourse {
  id: string;
  slug: string;
  name: string;
  description: string;
  category_id: string;
  subcategory_id: null;
  price: number;
  team_id: string;
  status: number;
  scope: number;
  level: number;
  label: number;
  thumbnail_url?: string;
  language: string;
  duration: number;
  skills: string;
  is_update: false;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  is_recording: boolean;
  _count: {
    Assignments: number;
    Lessons: number;
    CourseSections: number;
    Comments: number;
    Students: number;
    WatchHistories: number;
    TeamCourses: number;
    ForumPosts?: number;
  };
}

const createdCourse = async (userId: string) =>
  await httpClient.get<ICourse[]>(api.course.getCreatedCourse(userId));

export const useCreatedCourses = (userId: string) =>
  useQuery([api.course.getCreatedCourse(userId)], () => createdCourse(userId), {
    select: ({ data }) => data || [],
  });

const listAllCourse = async (page: number, search: string | null) =>
  await httpClient.get<IPaginatedResult<ICourse>>(api.course.all(page, search));

export const useAllCourses = (page: number, search: string | null) =>
  useQuery([api.course.all(page, search)], () => listAllCourse(page, search), {
    select: ({ data }) => data || [],
  });

export interface ICourseDetails extends ICourse {
  teachers: ITeacherProfile[];
}

const listOneCourse = async (id: string) =>
  await httpClient.get<ICourseDetails>(api.course.one(id));

export const useOneCourses = (courseID: string) =>
  useQuery([api.course.one(courseID)], () => listOneCourse(courseID), {
    select: ({ data }) => data || {},
  });

export interface ICourseUser {
  Users: IUser;
  enrollment_date: string;
  price: number;
  status: 1 | 2 | 3;
  id: string;
  is_deleted: boolean;
  deleted_on?: string;
}

const listCourseMember = async (id: string) =>
  await httpClient.get<ICourseUser[]>(api.course.user(id));

export const useCourseMember = (courseID: string) =>
  useQuery([api.course.user(courseID)], () => listCourseMember(courseID), {
    select: ({ data }) => data || {},
  });
