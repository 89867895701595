import {
  Checkbox,
  Group,
  Pagination,
  ScrollArea,
  Table,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useListAllExam } from "../../utils/services/examService";

const Exams = () => {
  const [params, setSearchParams] = useSearchParams();
  let page = parseInt(params.get("page") ?? "1");
  const [search, setSearch] = useState(
    (params.get("search") as string) ?? null
  );

  const { data, isSuccess, isLoading } = useListAllExam(
    page,
    params.get("search") as string
  );

  return (
    <ScrollArea>
      <Group aria-expanded={true}>
        <TextInput
          style={{ width: "600px" }}
          radius={"xl"}
          value={search}
          placeholder="search"
          aria-expanded={"true"}
          mx={10}
          size={"xl"}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === "Enter") {
              // @ts-ignore
              e.target.blur();
              const s = e.currentTarget.value;
              params.set("search", s);
              params.delete("page");
              setSearchParams(params, { replace: true });
            }
          }}
        />
      </Group>
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Time</th>
            <th>Enrolled Users</th>
            <th>Teachers</th>
            <th>Durations</th>
            <th>End time</th>
            <th>Question Marking</th>
            <th>Question Set Type</th>
            <th>Price</th>
            <th>Negative Marking</th>
            <th>Total Question</th>
            <th>Show Details</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data.map((x) => (
            <tr key={x.id} style={{ maxHeight: "20px" }}>
              <td
                style={{
                  maxWidth: "150px",
                  overflow: "hidden",
                  maxLines: 2,
                }}
              >
                {x.name}
              </td>
              <td style={{ maxWidth: "200px" }}>
                {new Date(x.start_time).toDateString()}
              </td>
              <td>{x._count.QuestionSetEnrollments}</td>
              <td>{x._count.QuestionSetTeachers}</td>
              <td>{x.duration / 60}</td>
              <td>{x.end_time}</td>
              <td>{x.question_marking}</td>
              <td>{x.question_set_type}</td>
              <td>{x.price}</td>
              <td>{x.negative_marking}</td>
              <td>{x.total_question}</td>
              <td>
                <Checkbox checked={x.show_result_details} />
              </td>
              <td>{x.status}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {isSuccess && (
        <Pagination
          mt={"lg"}
          page={data.meta.currentPage}
          onChange={(e) => {
            params.set("page", e.toString());
            setSearchParams(params);
          }}
          total={data.meta.lastPage}
        />
      )}
    </ScrollArea>
  );
};

export default Exams;
