import { Group, Tooltip } from "@mantine/core";
import { IconExternalLink, IconEye } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import { ICourse } from "../../utils/services/courseService";

const CourseRow = ({ data }: { data: ICourse[] }) => {
  return (
    <>
      {data.map((row) => (
        <tr key={row.id}>
          <td>{row.name}</td>
          <td>{new Date(row.created_on).toDateString()}</td>
          <td>{row.status}</td>
          <td>{row._count?.Lessons}</td>
          <td>{row._count?.CourseSections}</td>
          <td>{row._count?.TeamCourses}</td>
          <td>{row.is_recording}</td>
          <td>{row._count?.Students}</td>
          <td>
            <Group>
              <Tooltip label="View details">
                <Link to={row.id}>
                  <IconEye color="green" />
                </Link>
              </Tooltip>
              <Tooltip label="Go to Application">
                <Link to={""}>
                  <IconExternalLink color="green" />
                </Link>
              </Tooltip>
            </Group>
          </td>
        </tr>
      ))}
    </>
  );
};

export default CourseRow;
