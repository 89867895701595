import {
  Group,
  Loader,
  Pagination,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SessionList from "../../components/Sessions/SessionList";
import { useAllSessions } from "../../utils/services/sessionService";

const Sessions = () => {
  let navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  let page = parseInt(params.get("page") ?? "1");
  const [search, setSearch] = useState(
    (params.get("search") as string) ?? null
  );

  const { data, isSuccess, isLoading } = useAllSessions(
    page,
    params.get("search") as string
  );
  return (
    <ScrollArea>
      <Group aria-expanded={true}>
        <TextInput
          style={{ width: "600px" }}
          radius={"xl"}
          value={search}
          placeholder="search"
          aria-expanded={"true"}
          mx={10}
          size={"xl"}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === "Enter") {
              // @ts-ignore
              e.target.blur();
              const s = e.currentTarget.value;
              params.delete("page");
              params.append("search", s);
              setParams(s);
            }
          }}
        />
      </Group>
      {isLoading && <Loader />}
      {isSuccess && <SessionList data={data.data} />}

      {isSuccess && (
        <Pagination
          mt={"lg"}
          page={data.meta.currentPage}
          onChange={(e) => {
            params.set("page", e.toString());
            setParams(params);
          }}
          total={data.meta.lastPage}
        />
      )}
    </ScrollArea>
  );
};

export default Sessions;
