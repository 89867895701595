import { Loader, ScrollArea, Table } from "@mantine/core";
import { useParams } from "react-router-dom";
import SessionList from "../../../../components/Sessions/SessionList";
import { useCreatedSession } from "../../../../utils/services/sessionService";

const CreatedSession = () => {
  const { id } = useParams();

  const { data, isSuccess, isLoading } = useCreatedSession(id as string);
  return (
    <ScrollArea>
      {isLoading && <Loader />}

      {isSuccess && <SessionList data={data} />}
    </ScrollArea>
  );
};

export default CreatedSession;
