import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import AppRoutes from "./Routers";
import { COLOR_SCHEME_KEY } from "./utils/constants";
import { NotificationsProvider } from "@mantine/notifications";
import AuthContext, { AuthProvider } from "./context/AuthProvider";

const App = () => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    (localStorage.getItem(COLOR_SCHEME_KEY) as "light" | "dark") ?? "light"
  );
  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme =
      value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(nextColorScheme);
    localStorage.setItem(COLOR_SCHEME_KEY, nextColorScheme);
  };
  const queryClient = new QueryClient();

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          /** Put your mantine theme override here */
          colorScheme,
          fontFamily: "Poppins, sans-serif",
          fontFamilyMonospace: "Monaco, Courier, monospace",
          headings: { fontFamily: "Poppins, sans-serif" },
          loader: "dots",
          dateFormat: "MMM DD, YYYY",
          primaryColor: "teal",
        }}
      >
        <NotificationsProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </QueryClientProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

export default App;
