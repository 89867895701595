import { HoverCard, Loader, ScrollArea, Table } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import React from "react";
import { Link, useParams } from "react-router-dom";
import UserProfile from "../../components/UserProfile";
import { useCourseMember } from "../../utils/services/courseService";

const CourseMember = () => {
  const { id } = useParams();
  const { data, isSuccess, isLoading } = useCourseMember(id as string);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <ScrollArea>
      {data && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Enrollment Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      <span>
                        {`${user.Users.first_name} ${user.Users.last_name}`}
                      </span>
                    </td>
                    <td>{user.Users.email}</td>
                    <td>{new Date(user.enrollment_date).toDateString()}</td>
                    <td>{user.status}</td>
                    <td>
                      <Link to={`/user/${user.Users.user_id}`}>
                        <IconEye />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </ScrollArea>
  );
};

export default CourseMember;
