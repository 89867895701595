import { Container, Center, Group, Avatar, Title } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandGithub,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBrandYoutube,
} from "@tabler/icons";
import { Link, useParams } from "react-router-dom";
import { useUserDetail } from "../../utils/services/userServices";
import CourseSection from "./Components/Course";
import EnrolledCourse from "./Components/Course/EnrolledCourse";
import ExamSection from "./Components/Exam";
import Providers from "./Components/Provider/Providers";
import SessionSection from "./Components/Session";

const UserDetails = () => {
  const { id } = useParams();
  const { isSuccess, isError, isLoading, data } = useUserDetail(id as string);
  return (
    <div>
      {isSuccess && (
        <Container fluid>
          <Center>
            <Avatar size={300} src={data.image_url}></Avatar>
          </Center>
          <Center inline={false}>
            <Title>{data.displayName}</Title>
          </Center>
          <Center>
            <Title size={"md"}>{data.email}</Title>
          </Center>

          <Center mt={20}>
            <Group>
              {data.instagram_url && (
                <Link to={data.instagram_url}>
                  <IconBrandInstagram height={30} width={30} />
                </Link>
              )}
              {data.facebook_url && (
                <Link to={data.facebook_url}>
                  <IconBrandFacebook height={30} width={30} />
                </Link>
              )}
              {data.github_url && (
                <Link to={data.github_url}>
                  <IconBrandGithub height={30} width={30} />
                </Link>
              )}
              {data.linkedin_url && (
                <Link to={data.linkedin_url}>
                  <IconBrandLinkedin height={30} width={30} />
                </Link>
              )}
              {data.twitter_url && (
                <Link to={data.twitter_url}>
                  <IconBrandTwitter height={30} width={30} />
                </Link>
              )}
              {data.youtube_url && (
                <Link to={data.youtube_url}>
                  <IconBrandYoutube height={30} width={30} />
                </Link>
              )}
            </Group>
          </Center>
          {/* provider Sections */}
          <Providers providerList={data.providerData} />
          {/* Course Section */}
          <CourseSection />
          {/* Session Section */}
          <SessionSection />
          {/* Exam Section */}
          <ExamSection />
        </Container>
      )}
    </div>
  );
};
export default UserDetails;
