import { Loader } from "@mantine/core";
import React, { createContext, useState, FC } from "react";
import { useEffect } from "react";
import { useReAuth } from "../utils/services/authService";

interface IAuthContext {
  auth: User;
  setAuth: React.Dispatch<React.SetStateAction<User>>;
  logout: () => void;
}

const AuthContext = createContext<IAuthContext | null>(null);

interface User {
  firstName: string;
  lastName: string;
  id: string;
  token: string;
  isLoggedIn: boolean;
}

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const reAuth = useReAuth();

  const [auth, setAuth] = useState<User>({
    firstName: "",
    lastName: "",
    id: "",
    token: "",
    isLoggedIn: false,
  });
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (reAuth.isSuccess) {
      setAuth(() => ({
        firstName: reAuth?.data?.firstName,
        id: reAuth?.data?.id,
        lastName: reAuth?.data?.lastName,
        token: reAuth?.data?.token?.token,
        isLoggedIn: true,
      }));
      setReady(() => true);
    }
    if (reAuth.isError) {
      setAuth({
        firstName: "",
        lastName: "",
        id: "",
        token: "",
        isLoggedIn: false,
      });
      setReady(() => true);
    }
  }, [reAuth.isSuccess, reAuth.isError]);

  const logout = () => {
    localStorage.removeItem("token");
    setAuth({
      firstName: "",
      lastName: "",
      id: "",
      token: "",
      isLoggedIn: false,
    });
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      {!ready && localStorage.getItem("token") ? <Loader /> : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
