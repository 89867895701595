import { useQuery } from "@tanstack/react-query";
import { ITeacherProfile } from "../Types";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface IAssignment {
  name: string;
  AssignmentAttachments?: [];
  Users: ITeacherProfile;
  Courses: {
    name: string;
    description: string;
    slug: string;
    id: string;
    thumbnail_url: string;
  };
  slug: string;
  id: string;
  due_date: string;
  created_on: string;
  Lessons: {
    name: string;
    thumbnail_url?: string;
    video_url?: string;
  };
  _count: {
    AssignmentAttachments: number;
    UserAssignments: number;
  };
}

const getAssignments = (courseId: string) => {
  return httpClient.get<IAssignment[]>(api.assignments.get(courseId));
};

export const useGetAssignment = (courseId: string) =>
  useQuery([api.assignments.get(courseId)], () => getAssignments(courseId), {
    select: (data) => data.data || [],
  });
