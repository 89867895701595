import { Box, Tabs, Title } from "@mantine/core";
import CreatedExam from "./CreatedExam";
import EnrolledExam from "./EnrolledExam";

const ExamSection = () => {
  return (
    <Box my={"xl"}>
      <Title>Exam </Title>
      <Tabs defaultValue="Enrolled" mt={"lg"}>
        <Tabs.List>
          <Tabs.Tab value="Enrolled">Enrolled</Tabs.Tab>
          <Tabs.Tab value="Created">Created</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Enrolled" pt="xs" mt={"lg"}>
          <EnrolledExam />
        </Tabs.Panel>

        <Tabs.Panel value="Created" pt="xs" mt={"lg"}>
          <CreatedExam />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default ExamSection;
