import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import RequireAuth from "../components/RequireAuth";
import CourseDetails from "../pages/courseDetails";
import Assignment from "../pages/courseDetails/Assignment";
import CourseMember from "../pages/courseDetails/CourseMember";
import Courses from "../pages/courses";
import Exams from "../pages/exams";
import LoginPage from "../pages/loginPage";
import Pools from "../pages/pools";
import SessionDetails from "../pages/sessionDetails.tsx";
import Sessions from "../pages/sessions";
import UserDetailsPage from "../pages/userDetailsPage";
import UserPage from "../pages/userPage";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<UserPage />} />
            <Route path="/exams" element={<Exams />} />
            <Route path="/pools" element={<Pools />} />

            <Route path="/sessions" element={<Sessions />} />
            <Route path="/sessions/:id" element={<SessionDetails />} />

            <Route path="/courses" element={<Courses />}></Route>
            <Route path="/courses/:id" element={<CourseDetails />}>
              <Route path="" element={<Assignment />} />
              <Route path="members" element={<CourseMember />} />
            </Route>
            <Route path="/user/:id" element={<UserDetailsPage />} />
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
