import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  Center,
} from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import Logo from "../../components/Logo";
import { useLogin } from "../../utils/services/authService";
import { showNotification } from "@mantine/notifications";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const LoginPage = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });

  const login = useLogin();
  const auth = useAuth();
  const onFormSubmit = (values: { email: string; password: string }) => {
    login.mutate({ email: values.email, password: values.password });
  };
  useEffect(() => {
    if (auth?.auth.isLoggedIn) {
      navigate(from, { replace: true });
    }
  }, [auth?.auth]);

  useEffect(() => {
    if (login.isError) {
      showNotification({
        // @ts-ignore
        message: login.error?.response.data.message,
        title: "Login Fail",
        color: "red",
      });
      login.reset();
    }
    if (login.isSuccess) {
      auth?.setAuth({
        firstName: login.data.data.firstName,
        lastName: login.data.data.firstName,
        id: login.data.data.id,
        isLoggedIn: true,
        token: login.data.data.token.token,
      });
      showNotification({
        // @ts-ignore
        message: "Successfully logged in",
        title: "Login Success",
        color: "green",
      });
    }
  }, [login.isError, login.isSuccess]);

  return (
    <Container size={420} my={40}>
      <Center m={"lg"}>
        <Link to={"/"}>
          <Logo />
        </Link>
      </Center>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Welcome back!
      </Title>
      <form onSubmit={form.onSubmit(onFormSubmit)}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            {...form.getInputProps("email")}
            label="Email"
            placeholder="your email address"
            required
          />
          <PasswordInput
            {...form.getInputProps("password")}
            label="Password"
            placeholder="Your password"
            required
            mt="md"
          />

          <Button loading={login.isLoading} fullWidth mt="xl" type="submit">
            Sign in
          </Button>
        </Paper>
      </form>
    </Container>
  );
};
export default LoginPage;
