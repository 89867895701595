import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import BASE_URL from "../env";

type RequestData = Record<string, any>;

const THREE_MINUTES = 3 * 60 * 1000;
const baseURL = "";
const baseConfig = { baseURL, timeout: THREE_MINUTES };
/**
 * Axios HTTP Client
//  * {@link https://github.com/axios/axios#request-config Axios Request Config}
 */
export const httpClient = {
  get: <T>(url: string, config?: AxiosRequestConfig<RequestData>) =>
    axios.get<T>(url, {
      ...baseConfig,
      ...config,
    }),

  post: <T>(
    url: string,
    data: RequestData,
    config?: AxiosRequestConfig<RequestData>
  ) =>
    axios.post<T>(url, data, {
      ...baseConfig,
      data,
      ...config,
    }),

  put: <T>(
    url: string,
    data: RequestData,
    config?: AxiosRequestConfig<RequestData>
  ) =>
    axios.put<T>(url, data, {
      ...baseConfig,
      ...config,
    }),

  patch: <T>(
    url: string,
    data: RequestData,
    config?: AxiosRequestConfig<RequestData>
  ) =>
    axios.patch<T>(url, data, {
      ...baseConfig,
      ...config,
    }),
  delete: <T>(url: string, config?: AxiosRequestConfig<RequestData>) =>
    axios.delete<T>(url, {
      ...baseConfig,
      ...config,
    }),
};

axios.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    const token = localStorage.getItem("token");
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
/**
 * Remove empty, null and undefined values
 * @param obj a record of key value pair
 * @returns a record that does not have empty, null or undefined values
 */
export function filterFalseyValues(obj: Record<string, any>) {
  for (const propName in obj) {
    if (["", null, undefined].includes(obj[propName])) {
      delete obj[propName];
    } else if (
      obj[propName] instanceof Object &&
      Object.keys(obj[propName]).length
    ) {
      obj[propName] = filterFalseyValues(obj[propName]);
    }
  }
  return obj;
}

export function toFormData(data: Record<string, any>) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

function buildFormData(
  formData: FormData,
  data: Record<string, any>,
  parentKey?: string
) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else if (parentKey) {
    const value =
      data instanceof Date ? data.toString() : (data as string | Blob);
    formData.append(parentKey, value);
  }
}
