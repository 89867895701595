import { useQuery } from "@tanstack/react-query";
import { IPaginatedResult } from "../Types";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface IEnrolledExam {
  id: string;
  question_set_id: string;
  user_id: string;
  price: number;
  is_deleted: false;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  activity_reason?: string;
  deleted_by?: string;
  deleted_on?: string;
  enrollment_date: string;
  enrollment_status: 1 | 2 | 3 | 4;
  QuestionSets: {
    id: string;
    slug: string;
    name: string;
  };
}
const enrolledExam = async (userId: string) =>
  await httpClient.get<IEnrolledExam[]>(api.exam.enrolled(userId));

export const useEnrolledExam = (userId: string) =>
  useQuery([api.exam.enrolled(userId)], () => enrolledExam(userId), {
    select: ({ data }) => data || [],
  });
export interface IExam {
  id: string;
  name: string;
  thumbnail_url: string;
  description: string;
  scope: 1 | 2;
  total_question: number;
  negative_marking: number;
  question_marking: number;
  question_set_type: number;
  allowed_retake: number;
  duration: number;
  start_time: string;
  end_time: string;
  status: 1 | 2 | 3 | 4;
  is_deleted: boolean;
  price: number;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  slug: string;
  show_result_details: boolean;
  _count: {
    QuestionSetEnrollments: number;
    QuestionSetResults: number;
    TeamQuestionSets: number;
    QuestionSetQuestions: number;
    QuestionSetTeachers: number;
  };
}

const createdExam = async (userId: string) =>
  await httpClient.get<IExam[]>(api.exam.created(userId));

export const useCreatedExam = (userId: string) =>
  useQuery([api.exam.created(userId)], () => createdExam(userId), {
    select: ({ data }) => data || [],
  });

const listAllExam = async (page: number, search: string | null) =>
  await httpClient.get<IPaginatedResult<IExam>>(api.exam.all(page, search));

export const useListAllExam = (page: number, search: string | null) =>
  useQuery([api.exam.all(page, search)], () => listAllExam(page, search), {
    select: ({ data }) => data || [],
  });
