import { Avatar, createStyles, Group, Text } from "@mantine/core";
import { IconAt } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import { ITeacherProfile } from "../utils/Types";
const useStyles = createStyles((theme) => ({
  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[5],
  },

  name: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

const UserProfile = ({ user }: { user: ITeacherProfile }) => {
  const { classes } = useStyles();

  const { user_id, email, image_url, first_name, last_name } = user;
  return (
    <div>
      <Link to={`/user/${user_id}`}>
        <Group noWrap>
          <Avatar src={image_url} size={94} radius="md" />
          <div>
            <Text size="lg" weight={500} className={classes.name}>
              {`${first_name} ${last_name}`}
            </Text>

            <Group noWrap spacing={10} mt={3}>
              <Text size="xs" color="dimmed">
                {email}
              </Text>
            </Group>
          </div>
        </Group>
      </Link>
    </div>
  );
};

export default UserProfile;
