import { Avatar, Group, Paper, Text, Title } from "@mantine/core";
import React, { FC } from "react";
import { IUserProvider } from "../../../../utils/services/userServices";

type Props = {
  provider: IUserProvider;
};

const ProviderDetails: FC<Props> = ({ provider }) => {
  return (
    <Paper
      radius="md"
      withBorder
      p="lg"
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
      })}
    >
      <Title size={"sm"} mb={"md"}>
        {provider.providerId}
      </Title>

      <Avatar src={provider.photoURL} size={"xl"} radius={120} mx="auto" />
      <Text align="center" size="lg" weight={500} mt="md">
        {provider?.displayName}
      </Text>
      <Text align="center" color="dimmed" size="sm">
        {provider.email}
      </Text>
      <Text align="center" color="dimmed" size="sm">
        {provider.phoneNumber}
      </Text>
    </Paper>
  );
};

export default ProviderDetails;
