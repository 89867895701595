import { useQuery } from "@tanstack/react-query";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface ITags {
  id: string;
  name: string;
}

const getTagList = () => httpClient.get<ITags[]>(api.tags.get);

export const useGetTagList = () =>
  useQuery([api.tags.get], getTagList, {
    select: (data) => data.data,
  });
