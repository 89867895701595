import { Box, Button, Select } from "@mantine/core";
import { useForm } from "@mantine/form";
import { FileInput } from "@mantine/core";
import { useAddQuestionInPool, useGetPool } from "../../utils/services/pools";
import { showNotification } from "@mantine/notifications";
import { useGetTagList } from "../../utils/services/tagService";
const Pools = () => {
  const pool = useGetPool();
  const tags = useGetTagList();

  const addPool = useAddQuestionInPool();
  const form = useForm({
    initialValues: {
      poolId: "",
      question: null,
      answer: null,
      tags: "",
    },
  });
  const onSubmit = async (values: {
    poolId: string;
    question: File;
    answer: File;
    tags: string;
  }) => {
    try {
      await addPool.mutateAsync({
        question: values.question,
        id: values.poolId,
        answer: values.answer,
        tags: values.tags,
      });
      form.reset();
      showNotification({
        message: "Question added successfully",
      });
    } catch (err) {
      showNotification({
        message: "Unable to add question at this moment",
        color: "red",
      });
    }
  };
  return (
    // @ts-ignore
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box sx={{ maxWidth: "400px" }}>
        <Box my={"sm"}>
          {pool.data && (
            <Select
              searchable
              withAsterisk
              {...form.getInputProps("poolId")}
              label="Select Pool"
              placeholder="Pick one"
              data={pool.data.map((x) => ({ value: x.id, label: x.name }))}
            />
          )}
        </Box>
        <Box my={"sm"}>
          {tags.data && (
            <Select
              searchable
              withAsterisk
              {...form.getInputProps("tags")}
              label="Select Tag"
              placeholder="Pick one Tag"
              data={tags.data.map((x) => ({ value: x.id, label: x.name }))}
            />
          )}
        </Box>
        <Box my={"sm"}>
          <FileInput
            withAsterisk
            label="Question"
            placeholder="Select question docx file"
            {...form.getInputProps("question")}
          />
        </Box>
        <Box my={"sm"}>
          <FileInput
            withAsterisk
            label="Answer"
            placeholder={"select answer docx file"}
            {...form.getInputProps("answer")}
          />
        </Box>
      </Box>
      <Button loading={addPool.isLoading} type="submit">
        Submit
      </Button>
    </form>
  );
};

export default Pools;
