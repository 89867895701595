import { Box, Tabs, Title } from "@mantine/core";
import React from "react";
import CreatedSession from "./CreatedSession";
import EnrolledSession from "./EnrolledSession";

const SessionSection = () => {
  return (
    <Box my={"xl"}>
      <Title>Session</Title>
      <Tabs defaultValue="Enrolled" mt={"lg"}>
        <Tabs.List>
          <Tabs.Tab value="Enrolled">Enrolled</Tabs.Tab>
          <Tabs.Tab value="Created">Created</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="Enrolled" pt="xs" mt={"lg"}>
          <EnrolledSession />
        </Tabs.Panel>

        <Tabs.Panel value="Created" pt="xs" mt={"lg"}>
          <CreatedSession />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
};

export default SessionSection;
