import { Table } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import { ISession } from "../../utils/services/sessionService";

const SessionList = ({ data }: { data: ISession[] }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Created At</th>
          <th>Start Date</th>
          <th>Status</th>
          <th>Scope</th>
          <th>Teams</th>
          <th>Enrolled Members</th>
          <th>Moderators</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {data?.length > 0 &&
          data.map((x) => {
            return (
              <tr>
                <td>{x?.name}</td>
                <td>{new Date(x.created_on).toDateString()}</td>
                <td>{new Date(x.start_date).toDateString()}</td>

                <td>{x?.Status}</td>
                <td>{x?.scope}</td>
                <td>{x?._count?.TeamLiveSessions}</td>
                <td>{x?._count?.LiveSessionMembers}</td>
                <td>{x?._count?.live_session_moderators}</td>
                <td>
                  <Link to={`/sessions/${x.id}`}>
                    <IconEye />
                  </Link>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default SessionList;
