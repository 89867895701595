import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./service-api";
import { httpClient } from "./service-axios";

export interface IPool {
  name: string;
  id: string;
}

const getPools = async () => await httpClient.get<IPool[]>(api.pool.get);

export const useGetPool = () =>
  useQuery([api.pool.get], () => getPools(), {
    select: ({ data }) => data || [],
  });

const addQuestionInPool = async ({
  id,
  answer,
  question,
  tags,
}: {
  question: File;
  answer: File;
  id: string;
  tags: string;
}) => {
  var data = new FormData();
  data.append("question", question);
  data.append("tags", tags);
  data.append("answer", answer);
  return await httpClient.post(api.pool.addQuestions(id), data);
};

export const useAddQuestionInPool = () =>
  useMutation([api.pool.get], addQuestionInPool);
