import { ScrollArea, Table, Text } from "@mantine/core";
import React from "react";
import { ICourse } from "../../utils/services/courseService";
import CourseRow from "./CourseRow";

const CourseList = ({ data }: { data: ICourse[] }) => {
  return (
    <ScrollArea>
      <Table
        horizontalSpacing="md"
        verticalSpacing="xs"
        sx={{ tableLayout: "fixed", minWidth: 700 }}
      >
        <thead>
          <tr>
            <th>
              <Text weight={500} size="sm">
                Title
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Created Date
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Status
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Lesson (No)
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Section (No)
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Team Course
              </Text>
            </th>
            <th>
              <Text weight={500} size="sm">
                Recording
              </Text>
            </th>

            <th>
              <Text weight={500} size="sm">
                Students
              </Text>
            </th>

            <th>
              <Text weight={500} size="sm">
                Actions
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            <CourseRow data={data} />
          ) : (
            <tr>
              <td>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default CourseList;
