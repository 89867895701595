import { useState } from "react";
import {
  Navbar,
  Center,
  Tooltip,
  UnstyledButton,
  createStyles,
  Stack,
} from "@mantine/core";
import {
  TablerIcon,
  IconCertificate,
  IconLogout,
  IconVideo,
  IconListDetails,
  IconUser,
} from "@tabler/icons";
import { MantineLogo } from "@mantine/ds";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.white,
    opacity: 0.85,

    "&:hover": {
      opacity: 1,
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.1
      ),
    },
  },

  active: {
    opacity: 1,
    "&, &:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.15
      ),
    },
  },
}));

interface NavbarLinkProps {
  icon: TablerIcon;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionDuration={0}>
      <UnstyledButton
        onClick={onClick}
        className={cx(classes.link, { [classes.active]: active })}
      >
        <Icon stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

const NavData = [
  { icon: IconUser, label: "Users", to: "/" },
  { icon: IconCertificate, label: "Course", to: "/courses" },
  { icon: IconVideo, label: "Live Session", to: "/sessions" },
  { icon: IconListDetails, label: "MCQ", to: "/exams" },
  { icon: IconListDetails, label: "Pools", to: "/pools" },
];

const CustomNavBar = () => {
  const auth = useAuth();
  const location = useLocation();

  const links = NavData.map((link, index) => (
    <Link to={link.to} key={link.label}>
      <NavbarLink
        active={location.pathname === link.to}
        {...link}
        key={link.label}
      />
    </Link>
  ));

  return (
    <Navbar
      width={{ base: 80 }}
      p="md"
      sx={(theme) => ({
        backgroundColor: theme.fn.variant({
          variant: "filled",
          color: theme.primaryColor,
        }).background,
      })}
    >
      <Center>
        <MantineLogo type="mark" inverted size={30} />
      </Center>
      <Navbar.Section grow mt={50}>
        <Stack justify="center" spacing={0}>
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section>
        <Stack justify="center" spacing={0}>
          <NavbarLink
            icon={IconLogout}
            label="Logout"
            onClick={() => auth?.logout()}
          />
        </Stack>
      </Navbar.Section>
    </Navbar>
  );
};
export default CustomNavBar;
