import { useQuery } from "@tanstack/react-query";
import { Interface } from "readline";
import { IPaginatedResult, ITeacherProfile } from "../Types";
import { api } from "./service-api";
import { httpClient } from "./service-axios";
import { IUser } from "./userServices";

export interface IEnrolledSession {
  id: string;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  is_deleted: boolean;
  deleted_by: boolean;
  deleted_on: boolean;
  EnrollmentMemberStatus: 1 | 2 | 3 | 4;
  activity_reason?: string;
  live_session_id: string;
  user_id: string;
  enrollment_date: string;
  price: number;
  LiveSessions: {
    id: string;
    name: string;
    slug: string;
  };
}

const enrolledSession = async (userId: string) =>
  await httpClient.get<IEnrolledSession[]>(api.session.enrolled(userId));

export const useEnrolledSession = (userId: string) =>
  useQuery([api.session.enrolled(userId)], () => enrolledSession(userId), {
    select: ({ data }) => data || [],
  });

export interface ISession {
  id: string;
  name: string;
  slug: string;
  description: string;
  price: string;
  image_url: string;
  event_type: number;
  scope: number;
  start_date: string;
  end_date: string;
  recurrence: string;
  seat: number;
  is_cancel: boolean;
  cancel_reason?: string;
  is_algolia_sent: boolean;
  course_id?: string;
  room_id: string;
  reject_reason?: string;
  NearestStartTime: string;
  NearestEndTime: string;
  Status: 1 | 2 | 3 | 4;
  demo_days: number;
  duration: number;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  license_id: string;
  host: number;
  _count: {
    live_session_moderators?: number;
    LiveSessionMembers?: number;
    TeamLiveSessions?: number;
    LiveSessionEvents?: number;
    LiveSessionReports?: number;
    SubTeamLiveSessions?: number;
  };
}

const createdSession = async (userId: string) =>
  await httpClient.get<ISession[]>(api.session.created(userId));

export const useCreatedSession = (userId: string) =>
  useQuery([api.session.created(userId)], () => createdSession(userId), {
    select: ({ data }) => data || [],
  });

const listAllSessions = async (page: number, search: string | null) =>
  await httpClient.get<IPaginatedResult<ISession>>(
    api.session.all(page, search)
  );

export const useAllSessions = (page: number, search: string | null) =>
  useQuery(
    [api.session.all(page, search)],
    () => listAllSessions(page, search),
    {
      select: ({ data }) => data || [],
    }
  );

export interface ILiveSessionDetails extends ISession {
  moderators: ITeacherProfile[];
  Users: ITeacherProfile;
}

const sessionDetails = async (sessionId: string) =>
  await httpClient.get<ILiveSessionDetails>(api.session.one(sessionId));

export const useSessionDetails = (sessionId: string) =>
  useQuery([api.session.one(sessionId)], () => sessionDetails(sessionId), {
    select: ({ data }) => data || {},
  });

export interface ISessionMember {
  id: string;
  created_by: string;
  created_on: string;
  updated_by: string;
  updated_on: string;
  is_deleted: boolean;
  deleted_by?: string;
  deleted_on?: string;
  EnrollmentMemberStatus: number;
  activity_reason?: string;
  live_session_id: string;
  user_id: string;
  enrollment_date: string;
  price: number;
  Users: IUser;
}

const sessionMember = async (sessionId: string) =>
  await httpClient.get<ISessionMember[]>(api.session.user(sessionId));

export const useSessionMember = (sessionId: string) =>
  useQuery([api.session.user(sessionId)], () => sessionMember(sessionId), {
    select: ({ data }) => data || {},
  });
