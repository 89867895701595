import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  Loader,
  Avatar,
  Tooltip,
  Stack,
  ScrollArea,
  Paper,
  Tabs,
} from "@mantine/core";
import RichTextEditor from "@mantine/rte";
import { IconCheck } from "@tabler/icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import UserProfile from "../../components/UserProfile";
import { useOneCourses } from "../../utils/services/courseService";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing.xl * 4,
    paddingBottom: theme.spacing.xl * 4,
  },

  content: {
    maxWidth: 500,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    maxWidth: 400,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: "4px 12px",
  },
}));

const CourseDetails = () => {
  const { id } = useParams();
  const { isLoading, isSuccess, data } = useOneCourses(id as string);
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const { tabValue } = useParams();
  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <ScrollArea>
          <Paper shadow={"xl"}>
            <Container p={"xl"}>
              <div className={classes.inner}>
                <div className={classes.content}>
                  <Title className={classes.title}>{data.name}</Title>
                  <RichTextEditor
                    color="dimmed"
                    mt={"md"}
                    readOnly
                    id="rte"
                    value={data.description}
                  />
                  <Group mt={30}>
                    <Title size={"lg"}> Teacher</Title>
                  </Group>
                  <List
                    mt={10}
                    spacing="sm"
                    size="sm"
                    type="unordered"
                    withPadding
                    listStyleType={"none"}
                  >
                    {data.teachers.map((teacher) => {
                      return (
                        <List.Item key={teacher.user_id}>
                          <Tooltip label={teacher.email}>
                            <UserProfile user={teacher} />
                          </Tooltip>
                        </List.Item>
                      );
                    })}
                  </List>
                </div>
                <Image src={data.thumbnail_url} className={classes.image} />
              </div>
            </Container>
          </Paper>
          <Paper>
            <Tabs
              defaultValue={`/courses/${id}`}
              value={tabValue}
              onTabChange={(value) => navigate(`${value}`)}
            >
              <Tabs.List>
                <Tabs.Tab value={`/courses/${id}`}>Assignment</Tabs.Tab>
                <Tabs.Tab value="Members">Members</Tabs.Tab>
              </Tabs.List>
            </Tabs>
            <Paper mt={15}>
              <Outlet />
            </Paper>
          </Paper>
        </ScrollArea>
      )}
    </>
  );
};
export default CourseDetails;
