export const api = {
  auth: {
    login: "/api/login",
    reAuth: "/api/reauth",
  },
  course: {
    getUserCourse: (userId: string) => `/api/courses/enrolled/${userId}`,
    getCreatedCourse: (userId: string) => `/api/courses/created/${userId}`,
    one: (courseId: string) => `/api/courses/${courseId}`,
    user: (courseId: string) => `/api/courses/${courseId}/users`,
    all: (page: number, search: string | null) =>
      search
        ? `/api/courses?page=${page}&search=${search}`
        : `/api/courses?page=${page}`,
  },
  session: {
    enrolled: (userId: string) => `/api/sessions/enrolled/${userId}`,
    created: (userId: string) => `/api/sessions/created/${userId}`,
    one: (sessionId: string) => `/api/sessions/${sessionId}`,
    user: (courseId: string) => `/api/sessions/${courseId}/users`,

    all: (page: number, search: string | null) =>
      search
        ? `/api/sessions?page=${page}&search=${search}`
        : `/api/sessions?page=${page}`,
  },
  exam: {
    enrolled: (userId: string) => `/api/exams/enrolled/${userId}`,
    created: (userId: string) => `/api/exams/created/${userId}`,
    all: (page: number, search: string | null) =>
      search
        ? `/api/exams?page=${page}&search=${search}`
        : `/api/exams?page=${page}`,
  },
  user: {
    get: (page: number, search: string | null) =>
      search
        ? `/api/users/?page=${page}&search=${search}`
        : `/api/users/?page=${page}`,
    one: (userId: string) => `/api/users/${userId}`,
  },
  assignments: {
    get: (courseId: string) => `/api/assignments/${courseId}`,
  },
  pool: {
    get: "/api/pools",
    addQuestions: (id: string) => `/api/pools/${id}`,
  },
  tags: {
    get: "/api/tags",
  },
};
