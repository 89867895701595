import { Group, Title } from "@mantine/core";
import React, { FC } from "react";
import { IUserProvider } from "../../../../utils/services/userServices";
import ProviderDetails from "./ProviderDetails";

type Props = {
  providerList: IUserProvider[];
};

const Providers: FC<Props> = ({ providerList }) => {
  return (
    <>
      <Title my={"xl"}>Logged In Providers</Title>
      <Group m={10}>
        {providerList.map((provider) => (
          <ProviderDetails key={provider.providerId} provider={provider} />
        ))}
      </Group>
    </>
  );
};

export default Providers;
