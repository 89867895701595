import { Button, Center, Loader, Table, Text } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { Link, useParams } from "react-router-dom";
import { useSessionMember } from "../../../utils/services/sessionService";
import CsvDownloadButton from 'react-json-to-csv'

const LiveSessionMember = () => {
  const { id } = useParams();
  const { isSuccess, isLoading, data } = useSessionMember(id as string);
  if (isLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }
  if (isSuccess && data.length === 0) {
    return (
      <Center>
        <Text> No member has enrolled in this session</Text>
      </Center>
    );
  }
  return (
    <>
      {isSuccess && (
        <>

          <CsvDownloadButton
            // @ts-ignore
            delimiter=","
            data={data.map(x => ({
              name: x.Users.first_name + " " + x.Users.last_name,
              email: x.Users.email,
              phoneNumber: x.Users.mobile_number,
              enrolledAt: x.enrollment_date,
              userId: x.user_id
            }))} />
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email / phone number</th>
                <th>Enrolled Date</th>
                <th>Enrollment Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id}>
                  <td>{`${user.Users.first_name} ${user.Users.last_name}`}</td>
                  <td>
                    {user.Users.email}{" "}
                    {user.Users.email && user.Users.mobile_number && "|"}
                    {user.Users.mobile_number}
                  </td>
                  <td>{new Date(user.enrollment_date).toDateString()}</td>
                  <td>{user.EnrollmentMemberStatus}</td>
                  <td>
                    <Link to={`/user/${user.Users.user_id}`}>
                      <IconEye />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table></>
      )}
    </>
  );
};

export default LiveSessionMember;
