import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ScrollArea,
  Loader,
  Pagination,
  TextInput,
} from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useListUsers } from "../../utils/services/userServices";

const UserPage = () => {
  const [params, setSearchParams] = useSearchParams();
  let page = parseInt(params.get("page") ?? "1");
  const [search, setSearch] = useState(
    (params.get("search") as string) ?? null
  );
  const { data, isSuccess, isLoading } = useListUsers(
    page,
    params.get("search") as string
  );
  const rows =
    data &&
    data?.data?.map((item) => (
      <tr key={item.user_id}>
        <td>
          <Group spacing="sm">
            <Avatar size={40} src={item.image_url} radius={40} />
            <div>
              <Text size="sm" weight={500}>
                {`${item?.first_name} ${item?.last_name} `}
              </Text>
              <Text size="xs" color="dimmed">
                {item?.email}
              </Text>
            </div>
          </Group>
        </td>

        <td>
          <Text>{item?.email}</Text>
        </td>
        <td>
          <Text>{item?.mobile_number}</Text>
        </td>
        <td>
          <Link to={`/user/${item.user_id}`}>
            <IconEye />
          </Link>
        </td>
      </tr>
    ));

  return (
    <ScrollArea>
      <Group aria-expanded={true}>
        <TextInput
          style={{ width: "600px" }}
          radius={"xl"}
          value={search}
          placeholder="search"
          aria-expanded={"true"}
          mx={10}
          size={"xl"}
          onChange={(e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === "Enter") {
              // @ts-ignore
              e.target.blur();
              const s = e.currentTarget.value;
              params.set("search", s);
              params.delete("page");
              setSearchParams(params, { replace: true });
            }
          }}
        />
      </Group>

      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>User</th>
            <th>Email </th>
            <th>Phone</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <Loader></Loader>}
          {rows}
        </tbody>
      </Table>
      {isSuccess && (
        <Pagination
          mt={"lg"}
          page={data.meta.currentPage}
          onChange={(e) => {
            params.set("page", e.toString());
            setSearchParams(params);
          }}
          total={data.meta.lastPage}
        />
      )}
    </ScrollArea>
  );
};

export default UserPage;
