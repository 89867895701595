import { useColorScheme } from "@mantine/hooks";
import { useEffect, useState } from "react";

const Logo = ({ className = "", type = "", ...props }) => {
  const [mounted, setMounted] = useState(false);
  const colorScheme = useColorScheme();

  // Wh;en mounted on client, now we can show the UI
  useEffect(() => setMounted(true), []);

  if (!mounted) return null;
  const colorName = colorScheme === "light" ? "#113645" : "#fff";
  const playButtonColor = colorScheme === "light" ? "#3ed1b0" : "#3ed1b0";
  const bigPlayButtonColor = colorScheme === "light" ? "#113645" : "#3ed1b0";
  const vColor = colorScheme === "light" ? "#3ed1b0" : "#fff";

  return (
    <svg
      fill="none"
      className={className}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="41"
      viewBox="0 0 1731.5 535.9"
      xmlSpace="preserve"
      enableBackground="new 0 0 1731.5 535.9"
    >
      <path
        fill={colorName}
        d="M736.9,438.8c0,23.3-16.7,40.6-40.6,40.6c-23.3,0-40-17.3-40-40.6V428c-21.5,35.2-59.7,54.3-102.1,54.3
	c-34,0-61.5-11.3-83.6-33.4c-21.5-22.7-32.2-53.7-32.2-93.1V208.3c0-25.7,17.9-43.6,43.6-43.6c25.7,0,43,17.9,43,43.6v119.4
	c0,49.5,24.5,74.6,60.3,74.6c38.2,0,65.1-25.7,65.1-77.6V208.4c0-25.7,17.3-43.6,43-43.6s43.6,17.9,43.6,43.6L736.9,438.8
	L736.9,438.8z"
      />
      <path
        fill={colorName}
        d="M808.6,205.9c0-22.1,16.1-38.2,38.2-38.2c23.3,0,38.8,16.1,38.8,38.2l0.6,25.1c19.1-44.2,47.8-66.3,86.6-66.3
	c26.3,0,44.8,18.5,44.8,44.2c0,26.9-18.5,44.8-44.8,44.8c-47.8,0-77.6,30.4-77.6,89.5v95.5c0,25.7-17.3,43.6-43,43.6
	s-43.6-17.9-43.6-43.6L808.6,205.9L808.6,205.9z"
      />
      <path
        fill={colorName}
        d="M1146.4,211.9v226.2c0,25.7-17.9,44.2-43.6,44.2c-26.3,0-44.2-18.5-44.2-44.2V211.9c0-25.7,17.9-44.2,44.2-44.2
	C1128.5,167.8,1146.4,186.3,1146.4,211.9z"
      />
      <path
        fill={colorName}
        d="M1311.8,102.1v336.1c0,25.7-17.9,44.2-43.6,44.2c-26.3,0-44.2-18.5-44.2-44.2V102.1c0-25.7,17.9-43.6,44.2-43.6
	C1293.9,58.5,1311.8,76.4,1311.8,102.1z"
      />
      <path
        fill={colorName}
        d="M1370.5,326.5c0-44.5,15.9-82,47.7-112.5c31.8-31.1,71.2-46.3,118.3-46.3c47.1,0,85.9,14.6,116.5,44.5
	c31.2,29.9,46.5,66.8,46.5,111.3s-15.9,82-47.7,113.1c-31.8,30.5-71.2,45.7-118.3,45.7c-47.1,0-85.9-14.6-117.1-44.5
	C1385.8,407.9,1370.5,371,1370.5,326.5z M1591.1,381.6c15.3-15.2,22.9-34,22.9-56.2c0-22.3-7.7-41-22.9-56.2
	c-14.7-15.2-33.5-22.9-55.9-22.9c-22.9,0-41.8,7.6-57.1,22.9c-14.7,15.2-22.4,34-22.4,56.2c0,22.3,7.7,41,22.4,56.2
	c15.3,15.2,34.1,22.8,57.1,22.8C1557.6,404.4,1576.4,396.8,1591.1,381.6z"
      />
      <path
        fill={playButtonColor}
        d="M1150,94.5l-50.7-33.9c-3.5-2.3-8.2-2.7-12-0.9s-6.3,5.5-6.3,9.5V137c0,4,2.4,7.7,6.3,9.5
	c1.7,0.8,3.4,1.2,5.3,1.2c2.4,0,4.8-0.7,6.8-2l50.7-33.9c3-2,4.8-5.2,4.8-8.6C1154.8,99.7,1153,96.5,1150,94.5z"
      />
      <path
        fill={vColor}
        d="M309.1,165.3l-7-0.8l-4.7,7.1c-29.2,44.1-58.5,88.1-87.5,132.3c-2.7,0-90.8-140.3-90.8-140.3
	c-14.4-23.5-42.7-29.4-68.4-13.7c-26.4,16.1-34,44-19.6,67.5c0,0,130,220.2,146.7,245.1c17,25.5,50.2,26.3,66,0l73.1-122.3
	l64.6-108.1C399.1,202.6,340.9,165.3,309.1,165.3z"
      />
      <path
        fill={bigPlayButtonColor}
        d="M377.3,194.3l-126.1-84.8c-8.8-5.9-20.3-6.7-30-2.2c-9.6,4.6-15.7,13.7-15.7,23.7v169.5
	c0,10,6.1,19.2,15.7,23.7c4.1,1.9,8.6,2.9,13.1,2.9c6,0,11.9-1.7,16.9-5.1l126.1-84.8c7.5-5,11.9-13,11.9-21.5
	C389.1,207.3,384.7,199.3,377.3,194.3z"
      />
    </svg>
  );
};

export default Logo;
