import { AppShell, Header, Text } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Logo from "../Logo";
import { SwitchToggle } from "../SwitchToggle";
import CustomNavBar from "./NavBar";

const Layout = ({ require = true }) => {
  return require ? (
    <AppShell
      padding="md"
      navbar={<CustomNavBar />}
      header={
        <Header height={70} p="md">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Logo />
            <SwitchToggle />
          </div>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  ) : (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
